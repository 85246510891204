.home-main {
  background-position-x: center;
  background-size: cover;
  margin-bottom: 160px;
  position: relative;
  width: 100%;

  &::after {
    background: url(../../src/img/home-main-bg-grid.svg) no-repeat center center;
    background-size: cover;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.hero {
  padding-top: calc(88px + 40px);
  position: relative;
  overflow: hidden;
  z-index: 1;

  &-main {
    display: flex;
    justify-content: space-between;

    &-text {
      margin-top: 92px;
      max-width: 550px;
      max-width: 580px;
  
      .flag-text {
        margin-bottom: 16px;
      }
  
      .btn {
        margin-top: 32px;
      }
  
      h1 {
        color: #fff;
      }
  
      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        margin-top: 12px;
        max-width: 443px;
        color: #fff;
      }
    }
  
    &-img {
      justify-content: center;
      display: flex;
      margin-right: 42px;
      max-width: 100%;
      position: relative;
      width: 523px;
  
      &-circle {
        background-color: #D9D9D9;
        border-radius: 50%;
        height: 523px;
        opacity: 0.05;
        position: absolute;
        left: 0;
        top: 73px;
        width: 523px;
      }
  
      img {
        position: relative;
        z-index: 1;
      }
    }

    &-box {
      &-price {
        animation: heroBox 6s infinite alternate linear;
        background-color: #fff;
        border-radius: 4px;
        left: 22px;
        padding: 12px 16px;
        position: absolute;
        top: 354px;
        z-index: 2;

        span {
          display: block;
          font-size: 1.3rem;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 1.7rem;
          margin-bottom: 4px;
          text-transform: uppercase;
        }

        p {
          align-items: center;
          display: flex;
          font-size: 2rem;
          font-weight: 600;
          line-height: 2.4rem;
          letter-spacing: -0.5px;

          small {
            align-items: center;
            background-color: rgba(0, 200, 141, 0.40);
            border-radius: 4px;
            display: inline-flex;
            height: 26px;
            font-size: 1.4rem;
            font-weight: 600;
            justify-content: center;
            margin-right: 7px;
            letter-spacing: -0.5px;
            line-height: 1.6rem;
            width: 26px;
          }
        }
      }

      &-item {
        animation: heroBox 6s infinite alternate linear;
        animation-delay: 4s;
        background: linear-gradient(113deg, rgba(255, 255, 255, 0.24) 20.7%, rgba(255, 255, 255, 0.01) 106.73%);
        border: 1px solid #FFF;
        border-radius: 6px;
        height: 186px;
        padding: 8px 10px 20px;
        position: absolute;
        right: 6px;
        top: 202px;
        width: 120px;
        z-index: 1;

        &-top {
          align-items: center;
          display: flex;
          margin-bottom: 6.5px;

          svg {
            & + svg {
              margin-left: 3px;
            }
          }
        }

        &-img {
          margin-bottom: 8px;
          
          img {
            border-radius: 2px;
          }
        }

        &-content {
          div {
            background-color: #FFF;
            border-radius: 2px;
            border: 1px solid #B8B8B8;
            opacity: 0.2;

            &:nth-child(1) {
              height: 26px;
              width: 100%;
            }

            &:nth-child(2) {
              height: 16px;
              width: 59px;
            }

            & + div {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  &-list {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding-block: 24px 21px;
    position: relative;
    z-index: 1;
  }
  
  &-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 208px;
    min-height: 152px;
    position: relative;
    width: 208px;

    & + .hero-item {
      margin-left: 83px;

      &::before {
        background-color: rgba(0, 45, 49, 0.35);
        content: '';
        display: block;
        height: 152px;
        left: -41px;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }

    &-icon {
      align-items: center;
      display: flex;
      height: 37px;
      justify-content: center;
      margin-bottom: 5px;
      width: 37px;
    }

    p {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.4rem;
      text-align: center;
    }
  }
}

.about {
  margin-top: 120px;
  position: relative;
  z-index: 1;

  &-content {
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    max-width: 1122px;
  }

  &-text {
    margin-left: 42px;
    max-width: 550px;
    padding-top: 22px;

    .flag-text {
      margin-bottom: 12px;
    }

    h2 {
      color: #fff;
      margin-bottom: 12px;
    }

    p {
      color: #fff;
      font-size: 1.8rem;
      line-height: 2.8rem;

      & + p {
        margin-top: 16px;
      }
    }
  }
}

.categories {
  &-top {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;

    .flag-text {
      margin-bottom: 12px;
    }

    h2 {
      text-align: center;
    }
  }

  &-list {
    display: flex;
  }

  &-item {
    max-width: 279px;
    position: relative;

    & + .categories-item {
      margin-left: 32px;

      &::before {
        background-color: #D4D4D4;
        content: '';
        display: block;
        height: 100%;
        left: -15.5px;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }

    &-img {
      margin-bottom: 16px;

      img {
        border-radius: 6px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }
    
    &-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 6px;
    }

    &-description {
      font-weight: 400;
      color: #595957;
      line-height: 2.3rem;
    }
    
    a {
      align-items: center;
      color: #01444A;
      display: flex;
      font-weight: 500;
      line-height: 2.3rem;
      margin-top: 22px;
      max-width: max-content;
      text-transform: uppercase;

      @include hover() {
        &:hover {
          svg {
            path {
              stroke: #00C88D;
            }
          }
        }
      }

      svg {
        margin-right: 10px;

        path {
          transition: 0.3s stroke;
        }
      }
    }
  }
}

.numbers {
  background-color: rgba(91, 170, 146, 0.09);
  margin-block: 140px;
  padding-block: 64px;

  &-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-item {
    max-width: 214px;

    &-stamp {
      align-items: center;
      display: flex;
      height: 134.04px;
      justify-content: center;
      margin-right: 5px;
      position: relative;
      width: 121px;

      &-letters {
        animation: stampRotate 14s infinite linear;
      }

      &-star {
        background: linear-gradient(143deg, #01444A -51.47%, #00C88D 162.8%);
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 52px;
        justify-content: center;
        left: calc(50%);
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 52px;
      }
    }

    &-title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 4px;

      strong {
        font-size: 34px;
        font-weight: 600;
      }
    }

    &-description {
      color: #595957;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.1rem;
    }
  }
}

.how-work {
  margin-bottom: 160px;

  &-left {
    max-width: 417px;

    .flag-text,
    h2 {
      margin-bottom: 12px;
    }

    .btn {
      margin-top: 32px;
    }

    p {
      color: #595957;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.8rem;
    }
  }

  &-right {
    max-width: 622px;

    .btn {
      display: none;
      margin-top: 64px;
      margin-inline: auto;
      max-width: max-content;
    }
  }

  &-list {
    display: grid;
    gap: 42px;
    grid-template-columns: 1fr 1fr;
  }

  &-item {
    &-number {
      align-items: center;
      border: 1px solid rgba(1, 68, 74, 0.20);
      border-radius: 2px;
      display: flex;
      height: 70px;
      justify-content: center;
      margin-bottom: 32px;
      position: relative;
      width: 70px;

      &::before {
        background: rgba(1, 68, 74, 0.10);
        content: '';
        height: 62px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 62px;
        z-index: -1;
      }

      strong {
        color: #01444A;
        font-size: 2.6rem;
        font-weight: 600;
        line-height: 2.6rem;
        position: relative;
        text-transform: uppercase;
        top: 1.5px;
        z-index: 2;
      }
    }

    &-title {
      color: #202020;
      display: block;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    &-description {
      line-height: 2.8rem;
      font-weight: 400;
    }

  }

  .container {
    display: flex;
    justify-content: space-between;
  }
}

.benefits {
  background-size: cover;
  overflow: hidden;
  padding-top: 54px;

  &-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1147px;
    width: 100%;
  }

  &-text {
    max-width: 556px;

    &-buttons {
      display: flex;
      margin-top: 32px;

      .btn {
        & + .btn {
          margin-left: 16px;
        }
      }
    }

    h2 {
      color: #fff;
      margin-bottom: 12px;
    }

    ul {
      li {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 1.8rem;
        line-height: 2.8rem;
        position: relative;

        &::before {
          align-items: flex-end;
          content: '';
          background-image: url(../../src/img/icon/benefits-arrow.svg);
          background-repeat: no-repeat;
          display: flex;
          height: 30px;
          margin-right: 10px;
          width: 15px;
        }

        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  &-img {
    display: flex;
    justify-content: center;
    max-width: 523px;
    position: relative;
    width: 100%;

    &.mobile {
      display: none;
    }
    
    &-circle {
      background-color: #D9D9D9;
      border-radius: 50%;
      height: 523px;
      opacity: 0.05;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: -37px;
      width: 523px;
    }

    img {
      position: relative;
      z-index: 2;
    }
  }
}

.faq {
  margin-block: 160px;

  &-top {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;

    .flag-text {
      margin-bottom: 12px;
    }

    h2 {
      text-align: center;
    }
  }

  &-main {
    margin-inline: auto;
    max-width: 817px;
  }

  &-item {
    & + .faq-item {
      margin-top: 28px;
    }

    &-btn {
      background-color: transparent;
      border: 1px solid #D5D5D5;
      border-radius: 4px;
      padding: 24px 22px;
      width: 100%;

      &.active {
        .faq-item {
          &-question {
            strong {
              color: #006E78;
            }
  
            svg {
              transform: rotate(180deg);

              path {
                stroke: #006E78;
              }
            }
          }
  
          &-answer {
            display: block;
            max-height: 4000px;
            padding-top: 38px;
  
            &::before {
              display: block;
            }
  
            div {
              animation: fade 0.3s forwards ease;
              animation-delay: 0.3s;
            }
          }
        }
      }
    }

    &-question {
      display: flex;
      justify-content: space-between;
      padding-block: 5px;

      strong {
        color: #202020;
        display: block;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.4rem;
        max-width: calc(100% - 36px);
        text-align: left;
        transition: 0.3s color;
      }

      svg {
        margin-top: 8px;
        transition: 0.3s transform;
      }
    }

    &-answer {
      position: relative;
      max-height: 0;
      display: none;

      &::before {
        background-color: #D9D9D9;
        content: '';
        display: none;
        height: 1px;
        left: 0;
        position: absolute;
        top: 19px;
        width: 100%;
      }

      div {
        opacity: 0;
        transition: 0.3s opacity;
      }

      p {
        color: #595957;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.8rem;
        text-align: left;

        & + p {
          margin-top: 6px;
        }
      }
    }
  }
}

.newsletter {
  background-color: #00626B;
  padding-block: 48px;

  &-left {
    h2 {
      color: #fff;
    }
  }

  &-right {
    max-width: 438px;
    width: 100%;

    form {
      align-items: center;
      display: flex;
      position: relative;

      input {
        border-radius: 4px 5px 5px 4px;
        width: 438px;
      }
      
      button {
        border-radius: 0 4px 4px 0;
        font-size: inherit;
        height: 52px;
        line-height: inherit;
        padding-top: 15px;
        position: absolute;
        right: 0;
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@include responsive(1279) {
  .hero {
    &-main {
      &-text {
        margin-top: 72px;
      }

      &-img {
        margin-right: 22px;

        &-circle {
          height: 500px;
          left: -70px;
          width: 500px;
        }
      }
    }

    &-item {
      & + .hero-item {
        margin-left: 18px;

        &::before {
          left: -9px;
        }
      }
    }
  }

  .about {
    &-content {
      align-items: center;
      flex-direction: column-reverse;
    }

    &-text {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 42px;
      margin-left: 0;
      max-width: 650px;

      h2,
      p {
        text-align: center;
      }
    }
  }

  .categories {
    &-list {
      display: grid;
      gap: 64px 52px;
      grid-template-columns: auto auto;
      margin-inline: auto;
      max-width: 610px;
    }

    &-item {
      & + .categories-item {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .numbers {
    &-list {
      display: grid;
      gap: 32px;
      grid-template-columns: auto auto;
      margin-inline: auto;
      max-width: max-content;
    }

    &-item {
      &-img {
        grid-column: 2 span;
        margin-inline: auto;
      }
    }
  }

  .how-work {
    &-right {
      margin-left: 32px;
    }
  }

  .benefits {
    &-content {
      flex-direction: column;
    }

    &-text {
      margin-bottom: 60px;

      h2 {
        text-align: center;
      }

      ul {
        margin-inline: auto;
        max-width: max-content;
      }
    }

    &-img {
      img {
        &.desktop {
          height: 540px;
          width: auto;
        }
      }
    }
  }
}

@include responsive(991) {
  .hero {
    &-main {
      align-items: center;
      flex-direction: column;

      &-text {
        align-items: center;
        flex-direction: column;
        display: flex;
        margin-top: 48px;

        h1,
        p {
          text-align: center;
        }
      }

      &-img {
        margin-right: 0;
        margin-top: 22px;

        &-circle {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-inline: 40px;
    }

    &-item {
      max-width: 100%;
      width: 100%;

      &:nth-child(3) {
        &::before {
          height: 1px;
          width: 100%;
        }
      }

      &:nth-child(4) {
        &::after {
          background-color: rgba(0, 45, 49, 0.35);
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      & + .hero-item {
        margin-left: 0;

        &::before {
          left: -0.5px;
        }
      }
    }
  }

  .how-work {
    position: relative;

    &-left {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin-bottom: 62px;

      .btn {
        display: none;
        bottom: 0;
        position: absolute;
        margin-top: 0;
      }
      
      h2,
      p {
        text-align: center;
      }
    }

    &-right {
      margin-inline: auto;

      .btn {
        display: block;
      }
    }

    &-list {
      grid-template-columns: 1fr;
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      strong,
      p {
        text-align: center;
      }
    }

    .container {
      align-items: center;
      flex-direction: column;
    }
  }

  .numbers {
    &-item {
      &-stamp {
        grid-column: 2 span;
        margin-inline: auto;
        order: -1;
      }
    }
  }
}

@include responsive(767) {
  .hero {
    &-main {
      &-box {
        &-item {
          height: 156px;
          padding: 8px 8px 20px;
          top: 168px;
          width: 100px;
        }
      }
    }
  }

  .categories {
    &-list {
      gap: 50px 14px;
    }

    &-item {
      &-description {
        min-height: 69px;
      }
    }
  }

  .newsletter {
    .container {
      flex-direction: column;
    }

    h2 {
      margin-bottom: 24px;
      text-align: center;
    }
  }
}

@include responsive(599) {
  // FLAG TEXT SPACE
  .flag-text {
    margin-bottom: 16px !important;
  }

  // SPACINGS BETWEEN SECTIONS
  .home-main {
    background-image: url(../../src/img/home-main-bg-mobile.jpg) !important;
    margin-bottom: 120px;
  }

  .numbers {
    margin-block: 100px;
    padding-block: 100px;
  }

  .how-work {
    margin-bottom: 100px;
  }

  .faq {
    margin-block: 100px;
  }

  .benefits {
    padding-block: 100px;
  }

  // STYLES
  .hero {
    &-main {
      &-text {
        margin-top: 18px;
      }

      &-img {
        &-circle {
          height: 360px;
          width: 360px;
        }

        > img {
          height: 400px;
          margin-left: 12px;
        }
      }

      &-box {
        &-price {
          left: 10px;
          top: 234px;
        }
      }
    }

    &-list {
      grid-template-columns: 1fr;
    }

    &-item {
      &::before {
        top: 1px;
      }

      & + .hero-item {
        &::before {
          height: 1px;
          left: -0.5px;
          width: 100%;
        }
      }

      &:nth-child(4) {
        &::before {
          display: none;
        }
      }
    }
  }

  .about {
    margin-top: 120px;

    &-text {
      padding-top: 0;
    }
  }

  .categories {
    &-list {
      gap: 64px;
      grid-template-columns: 1fr;
      max-width: 279px;
    }

    &-item {
      &-description {
        min-height: unset;
      }

      a {
        margin-top: 14px;
      }
    }
  }

  .numbers {
    &-list {
      gap: 64px;
      grid-template-columns: auto;
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      &-img {
        grid-column: 1;
      }

      &-stamp {
        grid-column: 1;
        order: unset;
      }

      h3,
      p {
        text-align: center;
      }
    }
  }

  .benefits {
    &-content {
      flex-direction: column-reverse;
    }

    &-text {
      margin-bottom: 0;

      &-buttons {
        flex-direction: column;
        justify-content: center;
        margin-top: 52px;

        .btn {
          justify-content: space-between;

          & + .btn {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }

      h2 {
        text-align: left;
      }
    }

    &-img {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
        height: 350px;
        width: auto;
      }

      &-circle {
        display: none;
      }

      img {
        margin-bottom: 48px;
      }
    }
  }

  .newsletter {
    &-right {
      form {
        input {
          width: 100%;
        }

        button {
          padding: 16px 18px 14px;
        }
      }
    }
  }
}

@include responsive(374) {
  .newsletter {
    &-right {
      form {
        flex-direction: column;

        input {
          border-radius: 4px;
        }
        
        button {
          border-radius: 4px;
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }
}