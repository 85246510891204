h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  text-transform: uppercase;
}

h1:not(.no-config),
.title-h1 {
  font-size: 5.4rem;
  line-height: 6.4rem;
  font-weight: 700;
}

h2:not(.no-config),
.title-h2 {
  font-size: 3.4rem;
  line-height: 4.6rem;
}

h3:not(.no-config),
.title-h3 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

@include responsive(1279) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
}

@include responsive(599) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 3.8rem;
    line-height: 4.5rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 2.6rem;
    line-height: 3.6rem;
  }
}