.header-simulator {
  background: #01444A;
  backdrop-filter: unset;
  position: relative;

  &-back {
    align-items: center;
    color: #fff;
    display: flex;

    &:hover {
      color: #00C88D;

      svg {
        margin-right: 12.5px;
        
        path {
          stroke: #00C88D;
        }
      }
    }

    svg {
      margin-right: 10px;
      transition: 0.3s;
      
      path {
        transition: 0.3s;
      }
    }
  }
}