.simulator {
  margin-block: 52px;

  &-box {
    display: flex;
    height: 606px;
    justify-content: center;
    margin-inline: auto;
    max-width: 990px;
    width: 100%;

    &-content {
      background-color: #01444A;
      border-radius: 4px 0 0 4px;
      padding: 36px 26px;
      width: 500px;
    }

    &-step {
      display: none;

      &-03 {
        align-items: center;
        height: 100%;

        &.active {
          display: flex !important;
        }

        h2,
        p {
          text-align: center;
        }

        p {
          font-size: 1.8rem;
          line-height: 2.6rem;
        }
      }

      &.active {
        animation: fadeRight 0.4s forwards ease;
        display: block;
      }
    }

    &-top {
      margin-bottom: 32px;

      &.step-01 {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
      }

      &.step-02 {
        h2 {
          margin-bottom: 6px;
        }
      }

      &.step-03 {
        h2 {
          margin-bottom: 6px;
        }
      }
    }

    &-title {
      color: #fff;
      font-weight: 400;
      font-size: 1.8rem;
      text-transform: initial;
      line-height: 2.2rem;

      strong {
        color: #00C88D;
        display: block;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3rem;
        margin-top: 8px;
        text-transform: uppercase;
      }
    }

    &-description {
      color: #fff;
    }

    &-option {
      position: relative;

      &-btn {
        align-items: center;
        background-color: #00616A;
        border-radius: 4px;
        display: flex;
        height: 34px;
        padding: 8px 11px;

        &.active {
          border-radius: 4px 4px 0 0;

          svg {
            transform: rotate(180deg);
          }
        }

        span {
          color: #fff;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.8rem;
          margin-right: 10px;
          opacity: 0.6;
          pointer-events: none;
          text-transform: uppercase;
          touch-action: none;
        }

        svg {
          pointer-events: none;
          touch-action: none;
          transition: transform 0.3s;
        }
      }

      &-list {
        background-color: #00616a;
        border-radius: 0 0 4px 4px;
        display: none;
        left: 0;
        position: absolute;
        top: 34px;
        width: 100%;
        z-index: 1;

        &.active {
          animation: fade 0.3s forwards;
          display: block;
        }

        li {
          & + li {
            border-top: 1px solid rgba(238,238,238,0.2);
          }

          a {
            color: #fff;
            font-size: 1.6rem;
            font-weight: 400;
            padding: 10px 11px;
            text-align: left;
            width: 100%;

            &.active {
              color: #00C88D;
            }
          }
        }
      }
    }

    &-arrow {
      bottom: 7px;
      position: relative;
    }

    &-form {
      height: 100%;

      &-content {
        &.form-grid-2 {
          display: grid;
          gap: 14px;
          grid-template-columns: 1fr 1fr;

          .box-input + .box-input {
            margin-top: 0;
          }
        }
      }
    }

    &-img {
      border-radius: 0 4px 4px 0;
      display: flex;
      flex: 1;
      height: 100%;
      position: relative;
      width: 100%;

      img {
        border-radius: 0 4px 4px 0;
        left: 0;
        object-fit: cover;
        object-position: top center;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: 0.3s opacity;

        &.active {
          opacity: 1;
        }
      }
    }

    .btn-form {
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      margin-top: 28px;
      width: 100%;

      & + .btn-form {
        margin-top: 16px;
      }
    }

    input {
      max-width: 100%;
      width: 100%;
    }

    label {
      color: #fff;
      margin-bottom: 6px;
    }
  }
}

@include responsive(767) {
  .simulator {
    &-box {
      &-content {
        border-radius: 4px;
      }

      &-img {
        display: none;
      }
    }
  }
}

@include responsive(599) {
  .simulator {
    &-box {
      height: auto;
      min-height: 606px;

      &-top {
        &.step-01 {
          align-items: flex-start;
          flex-direction: column;
        }
      }

      &-option {
        margin-top: 12px;

        &-list {
          li {
            a {
              padding: 8px;
            }
          }
        }
      }

      &-form {
        &-content {
          &.form-grid-2 {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}