.btn {
  align-items: center;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #003237;
  display: inline-flex;
  font-weight: 500;
  line-height: 2.8rem;
  padding: 17px 28px 15px;
  text-transform: uppercase;
  transition: 0.3s;

  &-accent {
    background-color: #00C88D;
    
    &-dark {
      background-color: #01444A;
      color: #fff;
      font-weight: 400;
    }
  }

  &-outline {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }

  &-hover {
    &-primary {
      @include hover() {
        &:hover {
          background-color: #fff;
          color: #01444A;

          svg {
            path {
              stroke: #01444A;
            }
          }
        }
      }
    }

    &-primary-outline {
      @include hover {
        &:hover {
          background-color: transparent;
          border-color: #fff;
          color: #fff;
        }
      }
    }

    &-accent {
      @include hover {
        &:hover {
          background-color: #00C88D;
          border-color: #00C88D;
          color: #003237;
    
          svg {
            path {
              stroke: #003237;
            }
          }
        }
      }
    }

    &-arrow {
      @include hover() {
        &:hover {
          svg,
          img {
            left: 2.5px;
          }
        }
      }
    }
  }

  &-text-center {
    justify-content: center;
  }

  &-loader {
    &.loading {
      pointer-events: none;
      touch-action: none;

      span {
        transform: translateY(-226%);
      }
    }

    &-box {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 26px;
      min-width: calc(100% + 2.5px);
      overflow: hidden;
      position: relative;

      span {
        display: block;
        margin-inline: auto;
        transform: translateY(0);
        transition: 0.3s transform;

        & + span {
          margin-top: 20px;
        }
      }
    }
  }

  &-whatsapp {
    align-items: center; 
    background-color: #fff;
    border-radius: 50%;
    bottom: 26px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.246)!important;
    display: flex;
    height: 50px;
    justify-content: center;
    position: fixed; 
    right: 26px;
    transition: 0.3s !important;
    width: 50px;
    z-index: 9;

    @include hover() {
      &:hover {
        background-color: #00C88D;
      }
    }
  
    &:not(.aos-animate) {
      opacity: 0;
    }
  
    &:not(.active) {
      opacity: 0 !important;
    }
    
    &.active,
    &.active.aos-animate {
      opacity: 1;
    }
  
    svg {
      left: 0.5px;
      position: relative;
    }
  }

  &.untouchable {
    opacity: 0.5;
    touch-action: none;
    pointer-events: none;
  }

  svg,
  img {
    margin-left: 8px;
  }

  svg {
    left: 0;
    position: relative;
    transition: 0.3s left;
    top: -1px;

    path {
      transition: 0.3s;
    }
  }
}

@include responsive(1279) {
  .btn {
    padding: 16px 28px 14px;
  }
}

@include responsive(992) {

}

@include responsive(599) {
  .btn {
    &-whatsapp {
      bottom: 20px;
      right: 20px;
      height: 45px;
      width: 45px;
    }
  }
}