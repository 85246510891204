.header {
  background: linear-gradient(111deg, rgba(255, 255, 255, 0.20) -8.95%, rgba(255, 255, 255, 0.00) 114%), #01444A;
  backdrop-filter: blur(50px);
  left: 0;
  padding-block: 19px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;

  &-overlay {
    display: none;
  }

  &-nav {
    &-logo {
      margin-bottom: 20px;
      padding: 4px 20px;
    }

    &-whats {
      .btn {
        color: #003237;
        font-size: 1.6rem;
        justify-content: space-between;
        margin: 4px 20px;
        max-width: calc(100% - 40px);

        @include hover() {
          &:hover {
            color: #fff !important;
          }
        }
      }
    }

    ul {
      align-items: center;
      display: flex;

      li {
        & + li {
          margin-left: 52px;
        }

        &.mobile {
          display: none;
        }

        a {
          color: #fff;
          position: relative;

          @include hover() {
            &:hover {
              color: #00C88D;
            }
          }
        }
      }
    }
  }

  &-btn-mobile {
    align-items: center;
    background: linear-gradient(143deg, #01444A -51.47%, #00C88D 162.8%);
    border-radius: 50%;
    display: none;
    flex-direction: column;
    height: 48px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 99;
    width: 48px;

    &.active {
      span {
        background-color: #fff;

        &:nth-child(1) {
          transform: rotate(-225deg);
        }
        
        &:nth-child(2) {
          display: none;
          transform: translateX(150%);
        }
        
        &:nth-child(3) {
          transform: rotate(45deg);
          margin-top: -3px;
        }
      }
    }

    span {
      background-color: #fff;
      border-radius: 2px;
      display: block;
      height: 2.5px;
      transition: .3s;
      width: 28px;

      & + span {
        margin-top: 6px;
      }

      svg {
        display: block;
      }
    }
  }

  .btn-whats {
    height: 48px;
    padding: 11px 20px 9px;

    @include hover() {
      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    svg {
      margin-top: -2px;
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@include responsive(992) {
  .header {
    &-nav {
      ul {
        li {
          a {
            @include hover() {
              &:hover {
                &::after {
                  width: 10px;
                }
              }

              &::after {
                background-color: #00C88D;
                border-radius: 4px;
                bottom: -5px;
                content: '';
                display: block;
                height: 2px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                transition: 0.3s width;
                width: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(991) {
  .header {
    &-overlay {
      background-color: rgba(0,0,0,0.8);
      display: block;
      height: 100vh;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      visibility: hidden;
      top: 0;
      touch-action: none;
      transition: 0.3s ease-in-out;
      width: 100%;
      z-index: 99;
      
      &.active {
        opacity: 1;
        visibility: initial;
        pointer-events: initial;
        touch-action: initial;
      }
    }
    
    &-nav {
      background-color: #01444a;
      height: 100vh;
      left: -120%;
      position: fixed;
      top: 0;
      transition: 0.3s left;
      width: 320px;
      z-index: 99;

      &.active {
        left: 0;
      }

      &-whats {
        .btn {
          display: flex !important;
        }
      }

      ul {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 16px;
        max-height: calc(100vh - 30px);
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          height: 3px;
          width: 3px;
        }
        
        &::-webkit--track {
          background-color: #eee;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #cccc;
        }

        li {
          width: 100%;

          & + li {
            margin-left: 0;
            margin-top: 18px;
          }

          &.mobile {
            display: block;
          }

          a {
            display: block;
            padding: 4px 20px;
            width: 100%;
          }
        }
      }
    }

    &-btn-mobile {
      display: flex;
    }

    .btn-whats {
      display: none;
    }
  }
}

@include responsive(599) {
  .header {
    &-nav {
      width: 260px;
    }
  }
}