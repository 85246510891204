.flag-text {
  background-color: rgba(0, 200, 141, 0.07);
  border: 1px solid #006E78;
  border-radius: 2px;
  color: #006E78;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 11px 20px;
  text-transform: uppercase;

  &-accent {
    color: #00C88D;
    background-color: rgba(0, 200, 141, 0.06);
    border-color: #00C88D;
  }
}