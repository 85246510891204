.footer {
  background-color: #01444A;
  padding-block: 48px 32px;

  &-title {
    color: #fff;
    display: block;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &-main {
    border-bottom: 1px solid rgba(255,255,255,0.1);
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &-left {
      max-width: 235px;

      img {
        margin-bottom: 14px;
      }

      p {
        line-height: 2.4rem;
      }
    }
    
    &-right {
      display: flex;
      position: relative;

      &::before {
        background-color: #FFF;
        content: '';
        display: block;
        height: 100%;
        left: -149.5px;
        opacity: 0.1;
        position: absolute;
        width: 1px;
      }
    }

    &-box {
      max-width: 240px;

      & + .footer-main-box {
        margin-left: 120px;
      }

      > div {
        & + div {
          margin-top: 32px;
        }
      }

      p {
        line-height: 1.8rem;

        & + p {
          margin-top: 8px;
        }
      }

      ul {
        li {
          line-height: 1.8rem;

          & + li {
            margin-top: 14px;
          }

          a {
            line-height: inherit;
          }
        }
      }
    }
  }

  &-bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-author {
      align-items: center;
      display: flex;

      img {
        bottom: -2px;
        margin-left: 6px;
        position: relative;
      }
    }

    p {
      line-height: 2.4rem;
      font-weight: 300;
    }

    a {
      color: #fff;
    }
  }

  &-networks {
    display: flex;

    &-item {
      & + .footer-networks-item {
        margin-top: 0;
        margin-left: 20px;
      }

      a {
        align-items: center;
        background-color: #003237;
        border-radius: 4px;
        display: flex;
        height: 40px;
        justify-content: center;
        padding-bottom: 1px;
        transition: 0.3s;
        width: 40px;

        @include hover() {
          &:hover {
            svg {
              path {
                stroke: #00C88D;
              }
            }
          }
        }

        svg {
          path {
            transition: 0.3s stroke;
          }
        }
      }
    }
  }

  p,
  li a {
    color: #CFDBDD;
  }

  a {
    @include hover() {
      &:hover {
        color: #00C88D;
      }
    }
  }
}

@include responsive(1279) {
  .footer {
    &-main {
      &-right {
        &::before {
          left: -38px;
        }
      }

      &-box {
        & + .footer-main-box {
          margin-left: 70px;
        }
      }
    }
  }
}

@include responsive(991) {
  .footer {
    &-main {
      flex-direction: column;

      &-left {
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        p {
          text-align: center;
        }
      }

      &-right {
        margin-top: 64px;

        &::before {
          height: 1px;
          left: 0;
          top: -32px;
          width: 100%;
        }
      }
    }
  }
}

@include responsive(767) {
  .footer {
    &-networks {
      &-item {
        & + .footer-networks-item {
          margin-left: 12px;
        }
      }
    }
  }
}

@include responsive(599) {
  .footer {
    &-main {
      &-right {
        align-items: center;
        flex-direction: column;
      }

      &-box {
        display: flex;
        flex-direction: column;

        & + .footer-main-box {
          margin-left: 0;
          margin-top: 42px;
        }

        strong,
        p {
          text-align: center;
        }

        ul {
          li {
            align-items: center;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    &-bottom {
      flex-direction: column;

      &-author {
        margin-top: 12px;
      }

      p {
        text-align: center;
      }
    }
  }
}