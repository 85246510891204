@keyframes heroBox {
  0% {
    transform: translate3d(0,0,0);
  }
  
  50% {
    transform: translate3d(0,-8px,0);
  }
  
  100% {
    transform: translate3d(0,8px,0);
  }
}

@keyframes stampRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderPageLogoText {
  0% {
    left: 0;
    opacity: 0;
  }

  10% {
    opacity: 0;
  }
  
  100% {
    left: 74px;
    opacity: 1;
  }
}

@keyframes loaderBtn {
  to {
    transform: rotate(1turn);
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translate3d(-30px,0,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translate3d(0,30px,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
}