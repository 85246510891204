.loader-page {
  align-items: center;
  background-color: #01444a;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.6s opacity ease;
  width: 100%;
  z-index: 9999;

  &.loaded {
    .loader-page {
      &-logo {
        max-width: 199px;

        &-img {
          padding-right: 6px;

          &-fill {
            animation: fade 0.3s forwards;
            animation-delay: 1s;
          }
        }

        &-text {
          animation: loaderPageLogoText 0.8s forwards ease;
          animation-delay: 0.3s;
          max-width: 100%;
        }
      }
    }
  }

  &.hide {
    animation: hide 0.3s forwards;
    animation-delay: 0.6s;

    .loader-page {
      &-logo {
        animation: hide 0.4s forwards ease-in-out;
      }
    }

    .loader {
      opacity: 0;
    }
  }

  &-logo {
    background-color: #01444a;
    align-items: center;
    display: flex;
    max-width: 64px;
    overflow: hidden;
    position: relative;
    transition: 0.5s;
    width: 100%;

    &-img {
      background-color: #01444a;
      padding-right: 0;
      position: relative;
      transition: 0.5s;
      z-index: 1;

      &-fill {
        left: 0;
        opacity: 0;
        position: absolute;
        transition: 0.3s opacity;
      }
    }

    &-text {
      left: 0;
      opacity: 0;
      position: absolute;

      img {
        height: 60px;
        min-width: max-content;
      }
    }
  }

  .loader {
    margin-top: 24px;
    transition: 0.3s opacity;
  }
}