@import './config/variables';

*,
*::after,
*::before {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

.w-full {
  width: 100%;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%;
}

body {
  color: #202020;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
}

ul {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: inherit;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: 0.3s color;
}

img {
  display: block;
  max-width: 100%;
}

p {
  & + p {
    margin-top: 2px;
  }
}

@include responsive(991) {
  html.no-scroll-mobile,
  html.no-scroll-mobile body {
    overflow: hidden;
  }
}