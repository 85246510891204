.toast {
  &-container {
    padding: 15px 10px 5px 20px;
    position: fixed;
    right: 0;
    top: 0;
    overflow-x: hidden;
    z-index: 9999;
  }

  &-close {
    border: none;
    border-radius: 0 2px 0 2px;
    cursor: pointer;
    color: #fff;
    padding: 5px 5px 3px;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      height: 11px;
      position: relative;
      top: 0px;
      width: 12px;
    }
  }
  
  &-element {
    animation: showToastAnimation 0.6s forwards;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    display: flex;
    margin-bottom: 10px;
    min-height: 55px;
    overflow: hidden;
    padding: 10px 8px 10px 12px;
    position: relative;
    transition: 0.3s;
    width: 312px;


    &.removing-content div.toast-message,
    &.removing-content button {
      display: none;
    }

    &.hide {
      animation: hideToastAnimation 0.8s forwards;
    }

    &.success {
      background: #43bf47;

      .toast-close {
        background: #30b134;
      }
    }

    &.error {
      background: #e9504f;

      .toast-close {
        background: #d74544;
      }
    }

    &.warning {
      background: #f9e81b;
      color: #333;

      .toast-close {
        background: #e9d919;
      }      
    }
    
    &.removing-content {
      min-height: 0;
      max-height: 0;
      padding: 0;
      margin-bottom: 0;
    }

    &.info {
      background: #2b93ff;

      .toast-close {
        background: #2c89e9;
      }
    }
  }

  &-message {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    padding-right: 20px;
  }
  
  &-progress {
    animation: progressAnimation linear 5s forwards;
    background: #ffffffb3;
    border-radius: 0 0 2px 2px;
    bottom: 0;
    height: 5px;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

@keyframes showToastAnimation {
  0% {
    transform: translateX(calc(100% + 10px));
  }

  50% {
    transform: translateX(-20px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes hideToastAnimation {
  0% {
    transform: translateX(0px);
  }

  20% {
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(calc(100% + 30px));
  }
}

@keyframes progressAnimation {
  from {
    border-radius: 0 0 0 2px;
    width: 100%;
  }

  to {
    width: 0%;
  }
}